import React, { useMemo } from 'react';
import type BaseStatisticsSummary from './base';
import type { CampaignPercentageStatsWithReplyOverNurture, PercentageTypeExtract } from '../type';
import { getPercentageStats, getPercentageCharts } from './helper';

const displayCharts: { [k: string]: boolean } = {
	ConnectionsOverInvites: true,
	ReplyOverConnections: true,
	NurtureReplyOverSent: true,
	NurtureReplyOverNurtureSent: true,
};

interface IWithPercentageChartsProps extends React.ComponentProps<typeof BaseStatisticsSummary> {
	campaignRatios: TypedObject<CampaignPercentageStatsWithReplyOverNurture>;
	isSingleDay: boolean;
	id: string;
}

const withPercentageCharts = (
	StatsSummary: React.FC<IWithPercentageChartsProps>,
	uniqueGetPercentageStats?: PercentageTypeExtract
) => (props: IWithPercentageChartsProps): JSX.Element => {
	const {
		id,
		campaignRatios,
		newStatCharts,
		showLegends = true,
		isSingleDay = false,
		statsOptions = [],
		campaignFilters = {},
		campaignColorLegends = {},
		...rest
	} = props;

	const percentageCharts = useMemo(
		() => {
			const percentageStats = uniqueGetPercentageStats ? uniqueGetPercentageStats(campaignRatios, campaignFilters) : getPercentageStats(campaignRatios, campaignFilters);
			return getPercentageCharts(percentageStats, displayCharts, statsOptions, isSingleDay, campaignColorLegends, id);
		},
		[getPercentageStats, campaignRatios, campaignFilters, id, campaignColorLegends, isSingleDay, statsOptions]
	);
	const charts = newStatCharts?.length ? [...percentageCharts, ...newStatCharts] : [];

	return (
		<StatsSummary
			campaignColorLegends={campaignColorLegends}
			campaignFilters={campaignFilters}
			newStatCharts={charts}
			showLegends={showLegends}
			statsOptions={statsOptions}
			campaignRatios={campaignRatios}
			id={id}
			isSingleDay={isSingleDay}
			{...rest}
		/>
	);
};

export default withPercentageCharts;
