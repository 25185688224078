import { Row, Col, Divider, Space, Typography } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import { Funnel, FunnelChart, LabelList, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import { DefaultAboveBelowAverageLegend } from './dotLegend';
import { fontWeightMedium, spacingXS } from '@copilot/common/constant/commonStyles';
import Title from '@copilot/common/typography/title';

/**
 * Height of the horizontal divider between blocks
 */
const DIVIDER_HEIGHT = 28;
/**
 * Border style to match dividers
 */
const BORDER_TOP_BOTTOM = '1px solid rgba(0, 0, 0, 0.06)';

/**
 * Divider with the ability to show rates
 */
const RateDisplayedDivider = styled(Divider)`
	&&&::before {
		width: 100%
	}

	&&&::after {
		width: 0%;
	}

	&&& {
		margin: 0;
		padding: 0;
		min-height: ${DIVIDER_HEIGHT}px;
	}
`;

interface FunnelBlockProps {
	/**
	 * Label to show on the divider
	 */
	dividerLabel?: ReactNode;
	/**
	 * Height of the funnel block
	 */
	height: string;
	/**
	 * Whether this is the starting funnel block
	 */
	isStart: boolean;
	/**
	 * Whether this is the last funnel block
	 */
	isEnd: boolean;
}

/**
 * Funnel block
 * @param props 
 * @returns 
 */
const FunnelBlock = ({
	dividerLabel,
	children,
	height,
	isStart,
	isEnd,
}: PropsWithChildren<FunnelBlockProps>) => {
	const style = {
		height,
		borderTop: isStart ? BORDER_TOP_BOTTOM : undefined,
		borderBottom: isEnd ? BORDER_TOP_BOTTOM : undefined,
		paddingBottom: isEnd ? DIVIDER_HEIGHT / 2 : undefined,
		paddingTop: isStart ? DIVIDER_HEIGHT / 2 : undefined,
	};
	return (
		<>
			<Row align="middle" style={style}>
				<Col span={24}>{children}</Col>
			</Row>
			{isEnd 
				? null
				: (
					<RateDisplayedDivider plain type='horizontal' orientation='right'>
						{dividerLabel ??  <span></span>}
					</RateDisplayedDivider>
				)
			}
		</>
	);
};

interface FunnelBlockListProps<T extends FunnelData = FunnelData> {
	/**
	 * Data to show funnel blocks for
	 */
	data: T[];
	/**
	 * Divider content between funnel blocks
	 */
	dividers: ReactNode[];
	/**
	 * Height of the funnel blocks
	 */
	height: string;
}

/**
 * A list of funnel blocks
 * @param props
 */
const FunnelBlockList = ({ data, dividers, height }: FunnelBlockListProps): JSX.Element => {
	const blockHeight = data.length === 0 ? '0' : `calc((${height} - 20px) / ${data.length})`;
	return (
		<>
			{
				data.map((datum, index, arr) => {
					const isStart = index === 0;
					const isEnd = index === arr.length - 1;
					const dividerHeight = isStart || isEnd ? DIVIDER_HEIGHT / 2 : DIVIDER_HEIGHT;
					const calculatedBlockHeight = `calc(${blockHeight} - ${dividerHeight}px)`;
					return (
						<FunnelBlock
							key={`${datum.name}-${index}`}
							height={calculatedBlockHeight}
							isStart={isStart}
							isEnd={isEnd}
							dividerLabel={dividers[index]}
						>
							{datum.name}
						</FunnelBlock>
					);
				})
			}
		</>
	);
};

/**
 * Structure required to use the funnel chart
 */
export type FunnelData = Readonly<{
	/**
	 * Name of the data
	 */
	name: string;
	/**
	 * Fill color
	 */
	fill: string;
	/**
	 * Value
	 */
	value: number;
}>;

interface ProspectingCampaignFunnelProps<TData extends FunnelData> {
	/**
	 * Height of the section
	 */
	height?: number | string;
	/**
	 * Width of the section
	 */
	width?: number | string;
	/**
	 * Data for the funnel chart
	 */
	data: TData[];
	/**
	 * Dividers used to separate the different funnel chart blocks
	 */
	dividers: ReactNode[];
	/**
	 * Color used for above average
	 */
	aboveAverageColor: string;
	/**
	 * Color used for below average
	 */
	belowAverageColor: string;
	/**
	 * Color used for average range
	 */
	averageColor: string;
}

/**
 * [Presentational]
 * Prospecting Campaign Funnel for organization dashboard
 * @param props
 */
const ProspectingCampaignFunnel = <T extends FunnelData>({
	data,
	dividers,
	aboveAverageColor,
	averageColor,
	belowAverageColor,
	height = '100%',
	width = '100%',
}: ProspectingCampaignFunnelProps<T>): JSX.Element => {
	const heightString = isNumber(height) ? `${height}px` : height;
	return (
		<Space direction='vertical' style={{ width: '100%' }}>
			<Title level={5}>Prospecting campaign funnel</Title>
			<Typography.Text type='secondary' style={{ fontWeight: fontWeightMedium,  marginTop: spacingXS }} >See how your organization's funnel is performing based on industry benchmarks we've collected.</Typography.Text>
			<DefaultAboveBelowAverageLegend aboveAverageColor={aboveAverageColor} averageColor={averageColor} belowAverageColor={belowAverageColor} />
			<div style={{ height, width, position: 'relative' }}>
				<Row style={{ height: height }}>
					<Col span={24} style={{ marginTop: '5px' }}>
						<FunnelBlockList data={data} dividers={dividers} height={heightString} />
					</Col>
				</Row>
				<Row style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
					<Col offset={6} span={12}>
						<ResponsiveContainer height={height} width={width}>
							<FunnelChart>
								<Funnel dataKey="value" data={data} isAnimationActive={false}>
									<LabelList
										position="insideStart"
										fill="#000"
										stroke="none"
										dataKey="value"
									/>
								</Funnel>
							</FunnelChart>
						</ResponsiveContainer>
					</Col>
				</Row>
			</div>
		</Space>
	);
};

export default ProspectingCampaignFunnel;
