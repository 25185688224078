import _ from 'lodash';

type PercentageProps = Readonly<{
	value: number;
	precision?: number;
}>;

/**
 * Show a formatted percentage
 */
const Percentage = ({ 
	value,
	precision = 1,
}: PercentageProps): JSX.Element => {
	const roundedValue = _.round(value, precision);
	const percentString = `${roundedValue}%`;
	return <span>{percentString}</span>;
};

export default Percentage;