import {
	useTenantDataQuery,
	useAdminOrgDataQuery,
	ITenantType,
	IAdminOrgDataQuery,
	IOrganizationMemberRole,
} from '@copilot/data/graphql/_generated';
import { isEmpty, isNil } from 'lodash';
import { formatName, throwError } from '@copilot/common/utils';
import { QueryResponseType } from '@copilot/common/hooks/types';
import { useState } from 'react';
import { useEffectAsync } from '@copilot/common/hooks/common';
import { AdminManager } from '@copilot/data/managers/admin';

export const TenantTypes = {
	leaf: ITenantType.Leaf,
	node: ITenantType.Node,
} as const;

export type TenantTypesType = ValueOf<typeof TenantTypes>;

type OrganizationModel = {
	/* organization's id */
	id: string;
	/* organization's name */
	name: string;
	/* organization admin's user id */
	adminUserId: string | undefined;
	/* customer success owner */
	customerSuccessOwnerName: string;
};

type TenantModel = {
	/* tenant's id */
	id: string;
	/* tenant's name */
	name: string;
	/* child tenants */
	childTenants: { id: string; name: string; type: TenantTypesType }[];
	/* child organizations */
	childOrganizations: OrganizationModel[];
};

type AccountOwner = {
	/* Account owner's id */
	id: string;
	/* Account owner's name */
	name: string;
};

type UpdateCallbacks = {
	onAccountOwnerSelected: (id?: string) => void;
	onTenantNameSearchTermChanged: (searchTerm: string) => void;
	onOrganizationNameSearchTermChanged: (searchTerm: string) => void;
};

const loadingState = {
	data: undefined,
	error: undefined,
	loading: true,
} as const;

/**
 * Hook to return account owners
 */
export function useAccountOwners(): QueryResponseType<AccountOwner[]> {
	const [loading, setLoading] = useState<boolean>(false);
	const [accountOwners, setAccountOwners] = useState<AccountOwner[]>([]);
	
	useEffectAsync(async () => {
		try {
			setLoading(true);
			const members = await AdminManager.getCSMembers();
			setAccountOwners(members.map(member => ({ id: member.userId, name: formatName(member.firstName, member.lastName) })));
		} finally {
			setLoading(false);
		}
		
	}, []);
	
	if (loading) return loadingState;
	return {
		data: accountOwners,
		loading: false,
		error: undefined,
	}; 
}

/**
 * Hook to return tenant data
 * @param tenantId
 */
export function useTenant(
	tenantId: string
): QueryResponseType<TenantModel> & UpdateCallbacks {
	const [ tenantSearchTerm, setTenantSearchTerm ] = useState<string>('');
	const [ organizationSearchTerm, setOrganizationSearchTerm ] = useState<string>('');
	const [accountOwnerId, setAccountOwnerId] = useState<string | undefined>();
	
	const {
		data: tenantData,
		loading: isLoadingTenant,
		error: queryTenantError,
	} = useTenantDataQuery({
		variables: {
			tenantFilter: {
				tenantIds: [tenantId],
			},
		},
		skip: isEmpty(tenantId),
	});

	const tenant = tenantData?.admin_tenants[0];
	const childTenantIds = tenant?.childTenants.map((childTenant) => childTenant.id) ?? [];
	const {
		data: orgsData,
		loading: isLoadingOrgs,
		error: queryOrgError,
	} = useAdminOrgDataQuery({
		variables: {
			orgFilter: {
				tenantIds: childTenantIds,
			},
		},
		skip: isEmpty(childTenantIds),
	});

	if (isLoadingTenant || isLoadingOrgs)
		return {
			...loadingState,
			onAccountOwnerSelected: setAccountOwnerId,
			onOrganizationNameSearchTermChanged: setOrganizationSearchTerm,
			onTenantNameSearchTermChanged: setTenantSearchTerm,
		};

	const queryError = queryTenantError ?? queryOrgError;
	if (!isNil(queryError))
		return {
			loading: false,
			error: queryError,
			data: undefined,
			onAccountOwnerSelected: setAccountOwnerId,
			onOrganizationNameSearchTermChanged: setOrganizationSearchTerm,
			onTenantNameSearchTermChanged: setTenantSearchTerm,
		};

	const orgs = orgsData?.admin_organizations ?? [];

	if (isNil(tenant)) return throwError('Query completed with no error but with no data');
	const childNodeTenants = tenant.childTenants
		.filter((child) => child.type == TenantTypes.node)
		.filter((child) =>
			child.name.toLowerCase().includes(tenantSearchTerm.toLowerCase())
		);
	
	const filteredOrganizations = orgs.filter((org) => {
		if (!org.name.toLowerCase().includes(organizationSearchTerm.toLowerCase())) return false;
		if (!isNil(accountOwnerId) && accountOwnerId !== org.customerSuccessOwner?.id) return false;
		return true;
	});
		
	return {
		loading: false,
		error: undefined,
		data: {
			id: tenant.id,
			name: tenant.name,
			childTenants: childNodeTenants,
			childOrganizations: filteredOrganizations.map(toOrganizationModel),
		},
		onAccountOwnerSelected: setAccountOwnerId,
		onOrganizationNameSearchTermChanged: setOrganizationSearchTerm,
		onTenantNameSearchTermChanged: setTenantSearchTerm,
	};
}

/**
 * Convert from dto to organization model
 * @param org
 */
function toOrganizationModel(
	org: ArrayElement<IAdminOrgDataQuery['admin_organizations']>
): OrganizationModel {
	return {
		id: org.id,
		name: org.name,
		adminUserId: org.members.find((member) => member.role == IOrganizationMemberRole.Admin && member.isActive)
			?.userId,
		customerSuccessOwnerName: isNil(org.customerSuccessOwner) ? '' : formatName(org.customerSuccessOwner.firstName, org.customerSuccessOwner.lastName),
	};
}
