export const AgencyCode = {
	cleverly: 'cleverly',
	clvwlbl: 'clvwlbl',
	prosocial: 'prosocial',
} as const;

const CleverlyCodes: ReadonlyArray<string> = [AgencyCode.cleverly, AgencyCode.clvwlbl];
/**
 * Check if platform is cleverly
 * @param isAgency 
 * @param agencyCode 
 * @returns Whether platform is cleverly
 */
export function isCleverly(isAgency: boolean, agencyCode: string): boolean {
	return isAgency === true && CleverlyCodes.includes(agencyCode);
}