import { FC, ReactNode } from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';

const { Title, Text } = Typography;

const SecondaryText = styled(Text)`
	&&& {
		color: ${(props) => props.theme['@text-color-secondary']};
	}
`;

interface IHeaderProps {
	title: string;
	body?: string;
	icon?: ReactNode;
	align?: 'left' | 'center';
}

/**
 * Header Section with different levels of text and an optional icon
 * @param {string} title the main title of the header
 * @param {string} body body text
 * @param {ReactNode} icon optional icon or button to be displayed in the top right corner
 * @param {string} align alignment of the title section
 */
const Header: FC<IHeaderProps> = (props) => {
	const {
		title, body, icon, align = 'left',
	} = props;
	return (
		<Row wrap={false}>
			<Col flex="auto" style={{ textAlign: align }}>
				<Title level={3} style={{ margin: 0 }}>{title}</Title>
				{body && (
					<SecondaryText>{body}</SecondaryText>
				)}
			</Col>
			{icon && (
				<Col>{icon}</Col>
			)}
		</Row>
	);
};

export default Header;
