import React, { useEffect } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { AdminManager } from '@copilot/data/managers/admin';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { OrganizationManager } from '@copilot/data';
import Store from '@copilot/common/store';
import {
	Row, Col, Button, Divider, Popconfirm,
} from 'antd';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useOrganizationChanger } from '@copilot/common/hooks/admin';

interface AdminDashboardProps {}
const AdminDashboard: React.FC<AdminDashboardProps> = () => {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	if (!activeMember) return null;

	const [, fetchOrganizations] = useFetch(
		AdminManager.getOrganizations,
		OrganizationActions.loadOrganization,
		(r) => r.results
	);
	const organizationsLoaded = useSelector(OrganizationSelectors.getOrganizations);

	useEffect(() => {
		fetchOrganizations();
	}, []);

	const changeOrganization = useOrganizationChanger();

	const deleteOrganization = (orgId: string) => {
		OrganizationManager.deleteOrganization(orgId)
			.then((response) => {
				if (response) {
					Store.Dispatch(OrganizationActions.deleteOrganization({ id: orgId }));
				}
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed to delete organization',
					description: 'Please try again',
				});
			});
	};
	
	return (
		<BasicContainer bordered={false}>
			<BasicContainer.Content>
				<h2>All Organizations</h2>
				<br />
				{organizationsLoaded.map((org) => (
					<Row key={org.id}>
						<Divider />

						<Col span={16}>
							<h3>{org.name}</h3>
						</Col>
						<Col span={4}>
							<Button
								onClick={() => {
									changeOrganization(org.id);
								}}
							>
								Impersonate
							</Button>
						</Col>
						<Col span={4}>
							<Popconfirm
								title={`Are you sure you want to delete ${org.name}?`}
								onConfirm={() => {
									deleteOrganization(org.id);
								}}
								okText="Yes"
								cancelText="No"
								placement="rightBottom"
							>
								<a>Delete</a>
							</Popconfirm>
						</Col>
					</Row>
				))}
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default AdminDashboard;
