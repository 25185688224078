import AttentionRequired from '@copilot/common/pages/campaignDashboard/summaryV2/attentionRequired';
import Store from '@copilot/common/store';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import { UtilityFunctions } from '@copilot/common/utils/common';
import {
	IAttentionLevel,
	ICampaignAttentionItem,
	ICampaignAttentionType,
	ICampaignSummaryAttentionItemsQuery,
	IOrgMemberAttentionItem,
	IOrgMemberAttentionType,
} from '@copilot/data/graphql/_generated';
import { Typography } from 'antd';
import { History } from 'history';
import { ComponentProps, ReactNode } from 'react';
import { AttentionRequiredLevels } from './constant';

type ActionMap = Readonly<
	Record<ICampaignAttentionType, (item: ICampaignAttentionItem) => void>
	& Record<IOrgMemberAttentionType, (item: IOrgMemberAttentionItem) => void>
>;

/**
 * Create a map that maps AttentionType to the action callback
 * @param overrides
 * @returns
 */
export function getActionMap(history: History, campaignMemberIdToCampaignIdMap: Record<string, string>, reconnectCallback: (item: IOrgMemberAttentionItem) => void, overrides?: Partial<ActionMap>): ActionMap {
	return {
		[ICampaignAttentionType.CampaignSearchListLow]:
			(item: ICampaignAttentionItem) => history.push(`/campaign/${campaignMemberIdToCampaignIdMap[item.memberId]}?tab=${CampaignDashboardTabKeys.SearchList}`, { searchListRedirectMemberId: item.memberId }),
		[ICampaignAttentionType.NoInvitesSetForProspectingCampaign]: (item: ICampaignAttentionItem) => history.push(`/campaign/${campaignMemberIdToCampaignIdMap[item.memberId]}?tab=${CampaignDashboardTabKeys.TeamMembers}`, { teamMembersRedirectMemberId: item.memberId }),
		[ICampaignAttentionType.NoMessagesSetForNurtureCampaign]: (item: ICampaignAttentionItem) => history.push(`/campaign/${campaignMemberIdToCampaignIdMap[item.memberId]}?tab=${CampaignDashboardTabKeys.TeamMembers}`, { teamMembersRedirectMemberId: item.memberId }),
		[IOrgMemberAttentionType.LoggedOutOfLinkedin]: reconnectCallback,
		[IOrgMemberAttentionType.SalesNavMissing]: () => {},
		...overrides,
	} as const;
}

/**
 * Map attention items to the issue's copy
 * @param type
 * @param campaignName
 * @returns
 */
function getAttentionItemCopy(type: ICampaignAttentionType, campaignName: string): ReactNode {
	switch (type) {
		case ICampaignAttentionType.CampaignSearchListLow:
			return <Typography.Text>Search list in <Typography.Text strong>{campaignName}</Typography.Text> is running low and a new search list will be needed soon.</Typography.Text>;
		case ICampaignAttentionType.NoInvitesSetForProspectingCampaign:
			return <Typography.Text>No invites set in <Typography.Text strong>{campaignName}</Typography.Text>. The campaign will not run until they are set.</Typography.Text>;
		case ICampaignAttentionType.NoMessagesSetForNurtureCampaign:
			return <Typography.Text>No messages set in <Typography.Text strong>{campaignName}</Typography.Text>. The campaign will not run until they are set.</Typography.Text>;
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map org attention items to the issue's copy
 * @param type
 * @returns
 */
function getOrgAttentionItemCopy(type: IOrgMemberAttentionType): ReactNode {
	switch (type) {
		case IOrgMemberAttentionType.LoggedOutOfLinkedin:
			return <Typography.Text>LinkedIn disconnected</Typography.Text>;
		case IOrgMemberAttentionType.SalesNavMissing:
			return <></>;
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map attention items to onClick event
 * @param type
 * @param campaignId
 * @param campaignMemberId
 * @returns
 */
function getActionItemOnClick(type: ICampaignAttentionType, campaignId: string, campaignMemberId: string): () => void {
	const history = Store.History;
	switch (type) {
		case ICampaignAttentionType.CampaignSearchListLow:
			return () => history.push(`/campaign/${campaignId}?tab=${CampaignDashboardTabKeys.SearchList}`, { searchListRedirectMemberId: campaignMemberId });
		case ICampaignAttentionType.NoInvitesSetForProspectingCampaign:
		case ICampaignAttentionType.NoMessagesSetForNurtureCampaign:
			return () => history.push(`/campaign/${campaignId}?tab=${CampaignDashboardTabKeys.TeamMembers}`, { teamMembersRedirectMemberId: campaignMemberId });
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map org member attention items to onClick event
 * @param type
 * @param history
 * @param orgMemberId
 * @returns
 */
function getOrgMemberActionItemOnClick(item: IOrgMemberAttentionItem, reconnectCallback: (orgMemberId: string) => void): () => void {
	switch (item.type) {
		case IOrgMemberAttentionType.LoggedOutOfLinkedin:
			return reconnectCallback.bind(undefined, item.memberId);
		case IOrgMemberAttentionType.SalesNavMissing:
			return () => {};
		default:
			return UtilityFunctions.assertUnreachable(item.type);
	}
}

/**
 * Convert item DTO to model
 * @param item
 * @param campaignName
 * @param campaignId
 * @returns
 */
export function toCampaignAttentionRequiredItem(item: ArrayElement<ICampaignSummaryAttentionItemsQuery['campaignSummary']['attentionItems']>, campaignName: string, campaignId: string): ArrayElement<ComponentProps<typeof AttentionRequired>['items']> {
	return {
		issue: getAttentionItemCopy(item.type, campaignName),
		level: item.level === IAttentionLevel.Warning ? AttentionRequiredLevels.warning : AttentionRequiredLevels.blocker,
		actionLabel: 'Update',
		onActionClick: getActionItemOnClick(item.type, campaignId, item.memberId),
	};
}

/**
 * Convert item DTO to model
 * @param item
 * @param history
 * @returns
 */
export function toOrgMemberAttentionRequiredItem(item: ArrayElement<ICampaignSummaryAttentionItemsQuery['orgMembers'][0]['attentionItems']>, reconnectCallback: (orgMemberId: string) => void): ArrayElement<ComponentProps<typeof AttentionRequired>['items']> {
	return {
		issue: getOrgAttentionItemCopy(item.type),
		level: item.level === IAttentionLevel.Warning ? AttentionRequiredLevels.warning : AttentionRequiredLevels.blocker,
		actionLabel: 'Reconnect',
		onActionClick: getOrgMemberActionItemOnClick(item, reconnectCallback),
	};
}
