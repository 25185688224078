import { OceanAssessmentType } from '@copilot/data/managers/personalizedInsights';
import { FC } from 'react';
import { DescribedRatingScale } from './describedRatingScale';

/**
 * Scale a value
 * @param value 
 */
const scale = (value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) => {
	return ((toMax - toMin) * (value - fromMin)) / (fromMax - fromMin) + toMin;
};

const oceanAssessmentRoundedScaling = (value: number) => {
	return Math.round(scale(value, 0, 10.0, 0, 4));
};

type OceanAssessmentProps = {
	oceanAssessment?: OceanAssessmentType
};

/**
 * [Presentational]
 * Displays all properties of OCEAN assessment on a 5 point scale with described selection
 * @param oceanAssessment 
 * @returns 
 */
export const OceanAssessment: FC<OceanAssessmentProps> = ({
	oceanAssessment,
}) => {
	const maxOceanRating = 5;
    
	return (
		<>
			<DescribedRatingScale
				maxRating={maxOceanRating} 
				characteristic='Openness' 
				barColour='#E7C686' 
				selectionDescription={oceanAssessment?.openness.level ?? ''} 
				selectionValue={oceanAssessmentRoundedScaling(oceanAssessment?.openness.score ?? 0)} 
				selectionHighlightColour='rgba(255, 227, 185, 0.46)' 
				selectionDescriptionColour='#763200' 
			/>
			<DescribedRatingScale 
				maxRating={maxOceanRating} 
				characteristic='Conscientiousness' 
				barColour='#92ED67' 
				selectionDescription={oceanAssessment?.conscientiousness.level ?? ''} 
				selectionValue={oceanAssessmentRoundedScaling(oceanAssessment?.conscientiousness.score ?? 0)} 
				selectionHighlightColour='rgba(173, 220, 151, 0.46)' 
				selectionDescriptionColour='#117600' 
			/>
			<DescribedRatingScale 
				maxRating={maxOceanRating} 
				characteristic='Extraversion' 
				barColour='#FF85D5' 
				selectionDescription={oceanAssessment?.extraversion.level ?? ''} 
				selectionValue={oceanAssessmentRoundedScaling(oceanAssessment?.extraversion.score ?? 0)} 
				selectionHighlightColour='rgba(255, 185, 223, 0.46)' 
				selectionDescriptionColour='#760063' 
			/>
			<DescribedRatingScale 
				maxRating={maxOceanRating} 
				characteristic='Agreeableness' 
				barColour='#8591FF' 
				selectionDescription={oceanAssessment?.agreeableness.level ?? ''} 
				selectionValue={oceanAssessmentRoundedScaling(oceanAssessment?.agreeableness.score ?? 0)} 
				selectionHighlightColour='rgba(195, 185, 255, 0.46)' 
				selectionDescriptionColour='#110076' 
			/>
			<DescribedRatingScale 
				maxRating={maxOceanRating} 
				characteristic='Neuroticism' 
				barColour='#85FFC4' 
				selectionDescription={oceanAssessment?.emotional_stability.level ?? ''} 
				selectionValue={oceanAssessmentRoundedScaling(oceanAssessment?.emotional_stability.score ?? 0)} 
				selectionHighlightColour='rgba(185, 255, 234, 0.46)' 
				selectionDescriptionColour='#006F76' 
			/>
		</>
	);
};