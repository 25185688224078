import React from 'react';
import {
	Button, Col, Popconfirm, Row,
} from 'antd';
import BatchActionPanel from '@copilot/common/components/containers/batchAction';

interface IFailedOutgoingPageBatchActionProps {
	selectedCount: number;
	onDismiss: () => void;
	onRetry: () => void;
}

/**
 * [Presentational] Batch Actions for Failed Outgoing Page
 * @param selectedCount number of selected data rows
 * @callback onDismiss called on dismiss message
 * @callback onRetry called on retry sending message
 */
const FailedOutgoingPageBatchAction: React.FC<IFailedOutgoingPageBatchActionProps> = (props) => {
	const {
		selectedCount, onDismiss, onRetry,
	} = props;

	return (
		<BatchActionPanel total={selectedCount} style={{ height: 0 }}>
			<Row gutter={[16, 0]}>
				<Col>
					<Popconfirm
						title="Are you sure you wish to dismiss the selected messages?"
						onConfirm={onDismiss}
						okText="Yes"
						cancelText="No"
					>
						<Button type="primary" ghost>
							Dismiss
						</Button>
					</Popconfirm>

				</Col>
				<Col>
					<Button
						onClick={onRetry}
						type="primary"
						ghost
					>
						Retry
					</Button>
				</Col>
			</Row>

		</BatchActionPanel>
	);
};
export default FailedOutgoingPageBatchAction;
