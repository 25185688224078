import React from 'react';
import { Select } from 'antd';
import { EnumUtils } from '@copilot/common/utils';

export enum CampaignFilterType {
	All = 'All',
	Prospecting = 'Prospecting',
	Nurture = 'Nurture'
}

interface CampaignTypeDropdownProps {
	onChange: (value: CampaignFilterType) => unknown;
	selectedCampaignType?: CampaignFilterType;
}

const campaignTypeOptions = EnumUtils.getEnumKeys(CampaignFilterType, 'string').map(
	(c: string) => <Select.Option key={c} value={c}>{c}</Select.Option>
);

/**
 * A dropdown for selecting campaign type filter
 */
const CampaignTypeDropdown: React.FC<CampaignTypeDropdownProps> = (props: CampaignTypeDropdownProps) => {
	const { onChange, selectedCampaignType } = props;

	const campaigntTypeSelector = (
		<Select
			placeholder="Campaign Type"
			key="campaignType"
			value={selectedCampaignType}
			onChange={onChange}
			style={{ minWidth: '150px' }}
		>
			{campaignTypeOptions}
		</Select>
	);

	return campaigntTypeSelector;
};

export default CampaignTypeDropdown;