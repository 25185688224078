import React, { useEffect } from 'react';
import {
	Form, Input, Button, Checkbox,
} from 'antd';
import { Validators } from '../common/validators';

interface EmailFormValues {
	alternateEmail: string;
	bcc: string;
	cc: string;
	isNotificationEnable: boolean;
}

interface EmailFormProps {
	onSubmit: (values: EmailFormValues) => Promise<any> | void;
	initialValues?: EmailFormValues;
}

const EmailForm: React.FC<EmailFormProps> = (props) => {
	const { onSubmit, initialValues = {} as EmailFormValues } = props;
	const [form] = Form.useForm();

	const handleFinish = (values: EmailFormValues) => {
		onSubmit?.(values);
	};

	useEffect(() => {
		form.setFieldsValue({
			alternateEmail: initialValues.alternateEmail || '',
			cc: initialValues.cc || '',
			bcc: initialValues.bcc || '',
			isNotificationEnable: initialValues.isNotificationEnable || '',
		});
	}, [form, initialValues]);

	return (
		<Form
			form={form}
			onFinish={handleFinish}
			scrollToFirstError
		>
			<Form.Item
				name="alternateEmail"
				rules={[{ type: 'email', message: 'Please enter a valid E-mail.' }]}
				style={{ display: 'block' }}
				label={(
					<span>
						<strong>Alternate Email</strong>
						{' '}
						(Alternative email to send email
						notifications to)
					</span>
				)}
			>
				<Input type="email" />
			</Form.Item>
			<Form.Item
				name="cc"
				rules={[{ validator: Validators.validateMultiEmails }]}
				style={{ display: 'block' }}
				label={(
					<span>
						<strong>cc</strong>
						{' '}
						(Add ccs to your notification emails. Emails are
						delimited by ';')
					</span>
				)}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="bcc"
				rules={[{ validator: Validators.validateMultiEmails }]}
				style={{ display: 'block' }}
				label={(
					<span>
						<strong>bcc</strong>
						{' '}
						(Add ccs to your notification emails. Emails are
						delimited by ';')
					</span>
				)}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="isNotificationEnable"
				valuePropName="checked"
			>
				<Checkbox
					onChange={(e) => {
						form.setFieldsValue({ isNotificationEnable: e.target.checked });
					}}
				>
					<span>
						<strong>Enable Emails</strong>
						{' '}
						( Check to enable emails based on
						notification toggles in the section below. Uncheck to disable all
						emails)
					</span>
				</Checkbox>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default EmailForm;
