import React, { useEffect, useMemo, useState } from 'react';
import { Features } from '@copilot/data/responses/interface';
import { useSelector } from 'react-redux';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { withLoading } from '@copilot/common/components/errorAndLoading/errorAndLoading';
import { canCreateCampaignTeam, canSeeTeamMembers } from '@copilot/common/utils/campaign';
import { CampaignFilterType } from './campaignTypeDropdown';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import AllCampaignsPage, { UserInfo } from './allCampaignsPage';
import { useCreateCampaign } from '@copilot/common/hooks/campaign';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Redirect } from 'react-router';
import { useCampaignSummariesQuery, ICampaignType, ICampaignStatus, IProspectingCampaignStats, ICampaignSummariesQuery } from '@copilot/data/graphql/_generated';
import _, { isEmpty } from 'lodash';
import { CampaignSummaryModel } from './allCampaignsTable';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import moment from 'moment';

const LAST_NUM_DAYS = 30;

interface UserAndOrgInfo extends UserInfo {
	readonly orgMembers: IOrganizationMember[];
}

/**
 * Attaches UserInfo to the component.
 * @param Component
 */
const withUserAndOrgInfo = <T extends UserAndOrgInfo & { isLoading: boolean },>(Component: React.FC<T>) => (props: T) => {
	const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const orgMembers = useSelector(OrganizationMemberSelectors.getAllOrgMembers);
	return (<Component {...props} organizationId={organizationId} activeMember={activeMember} orgMembers={orgMembers} isLoading={!organizationId || !activeMember || !orgMembers} />);
};

type CampaignSummaryStats = ICampaignSummariesQuery['campaignSummaries'][0]['stats'];

/**
 * Type check a stats object based on the originating campaign type
 * @param stats The stats object we're checking
 * @param campaignType The type of campaign it originated from
 */
const isProspectingStats = (stats: CampaignSummaryStats, campaignType: ICampaignType): stats is IProspectingCampaignStats => campaignType === ICampaignType.Prospecting;

/**
 * Get stats for the Campaign Summary Model
 * @param stats The stats we're converting
 * @param campaignType The type of the campaign the stats are coming from
 * @returns The stats object in a CampaignSummaryModel
 */
const getStatsForSummaryModel = (stats: CampaignSummaryStats, campaignType: ICampaignType): CampaignSummaryModel['stats'] => {
	let result: CampaignSummaryModel['stats'] = {
		numUserReplies: stats?.numUserReplies ?? 0,
		percentReplied: stats?.percentReplied ?? 0,
		numUsersConnected: 0,
		numInvitesSent: 0,
		percentConnected: undefined,
	};
	if (isProspectingStats(stats, campaignType)) {
		result = {
			...result,
			numUsersConnected: stats.numUsersConnected,
			numInvitesSent: stats.numInvitesSent,
			percentConnected: stats.percentConnected ?? 0,
		};
	}	
	return result;
};

/**
 * Convert CampaignSummary results to CampaignSummaryModel
 * @param summary 
 * @returns 
 */
const toCampaignSummaryModel = (summary: ICampaignSummariesQuery['campaignSummaries'][0]): CampaignSummaryModel => {
	const summaryType = summary.type ?? ICampaignType.Prospecting;
	return {
		id: summary.id,
		name: summary.name ?? '',
		type: summaryType,
		status: !_.isNil(summary.status) ? summary.status : ICampaignStatus.Enabled,
		numActiveMembers: !_.isNil(summary.numActiveMembers) ? summary.numActiveMembers : 0,
		stats: getStatsForSummaryModel(summary.stats, summaryType),
		members: summary.members,
	};
};

/**
 * Functional container component for all campaigns page
 * Responsible for handling data fetching and state updates
 * @param props 
 * @returns 
 */
export const AllCampaignsPageContainerInternal: React.FC<UserAndOrgInfo> = (props) => {
	const {
		activeMember, organizationId, orgMembers,
	} = props;

	const thirtyDaysAgoMs = useMemo(() => {
		const thirtyDaysAgo = moment().subtract(LAST_NUM_DAYS, 'days');
		const midnight = thirtyDaysAgo.startOf('day');

		return midnight.valueOf();
	}, []);

	const { data, error, loading } = useCampaignSummariesQuery({
		variables: {
			start: thirtyDaysAgoMs,
		},
	});

	const campaignSummaryData = useMemo(() => {
		if (isEmpty(data) || isEmpty(data?.campaignSummaries)) {
			return [];
		} else {
			return data?.campaignSummaries.map<CampaignSummaryModel>(toCampaignSummaryModel) ?? [];
		}
	}, [data]);

	const isEnabled = useFeatureToggle(Features.NewAllCampaignFeature);
	const [canCreate, setCanCreate] = useState(false);
	const [showActiveAndTeamMembers, setShowActiveAndTeamMembers] = useState(false); 
	useEffect(() => {
		setCanCreate(canCreateCampaignTeam(activeMember));
		setShowActiveAndTeamMembers(canSeeTeamMembers(activeMember));
	}, [activeMember]);

	const [searchTerm, setSearchTerm] = useState('');
	const [campaignFilterType, setCampaignFilterType] = useState<CampaignFilterType>(CampaignFilterType.All);
	const [teamMemberFilters, setTeamMemberFilters] = useState<FilterDefinition[]>([]);
	useEffect(() => {
		const isMemberIdFilteredMap: { [key: string]: boolean } = {};
		for (const filter of teamMemberFilters) {
			isMemberIdFilteredMap[filter.key] = filter.isVisible;
		}
		
		// only update team member filters when there is difference
		if (teamMemberFilters.length === orgMembers.length) {
			const hasNewMember = orgMembers.some(member => {
				return _.isNil(isMemberIdFilteredMap[member.id]);
			});
			if (!hasNewMember) return;
		}

		// create team member filters based on the list of org members
		setTeamMemberFilters(orgMembers.map(m => {
			const memberId = m.id;
			return new FilterDefinition({
				key: memberId,
				label: `${m.firstName} ${m.lastName}`,
				isVisible: isMemberIdFilteredMap[memberId] ?? false,
			});
		}));
	}, [orgMembers]);

	return (isEnabled
		? (<AllCampaignsPage
				activeMember={activeMember}
				canCreate={canCreate}
				showActiveAndTeamMembers={showActiveAndTeamMembers}
				campaignSummaryData={campaignSummaryData}
				isLoading={loading}
				isError={!isEmpty(error)}
				organizationId={organizationId}
				campaignFilterType={campaignFilterType}
				onCampaignFilterChanged={setCampaignFilterType}
				teamMembersFilter={teamMemberFilters}
				onTeamMembersFilterChanged={setTeamMemberFilters}
				onCreateNewCampaign={useCreateCampaign()}
				campaignNameSearchTerm={searchTerm}
				onCampaignNameSearchTermChanged={setSearchTerm}
		/>)
		: (<Redirect to='/campaigns' />)
	);
};

const AllCampaignsPageContainer = withUserAndOrgInfo(withLoading(AllCampaignsPageContainerInternal));
export default AllCampaignsPageContainer;