import React, { ComponentProps, useContext } from 'react';
import {
	ResponsiveContainer,
	RadialBarChart,
	RadialBar,
	Tooltip,
} from 'recharts';
import { invertColorHex } from '@copilot/common/utils/colorHelper';
import { Config } from '@copilot/common/config';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { ThemeContext } from 'styled-components';

type StatData = { name: string; value: number };
interface PercentageGraphProps extends ComponentProps<typeof RadialBarChart> {
	color?: string;
	data: [StatData, StatData];
}

const PercentageGraph: React.FC<PercentageGraphProps> = (props: PercentageGraphProps) => {
	const themeContext = useContext(ThemeContext);
	const invertedColor = themeContext['@inverted-graph-color'];
	const { data, color = themeContext['@highlight-color'] } = props;
	const isDataValid = data[1].value > 0 && data[0].value > 0;
	const chartData: [StatData, StatData] = isDataValid
		? data
		: [
			{ name: 'numerator', value: 0 },
			{ name: 'denominator', value: 1 },
		];
	const graphData = React.useMemo(
		() => [
			{
				...chartData[1],
				fill: Config.isAgency ? invertedColor : invertColorHex(color),
			},
			{
				...chartData[0],
				fill: color,
			},
		],
		[chartData]
	);

	return (
		<ResponsiveContainer minHeight={173} minWidth="100%">
			<RadialBarChart
				height={125}
				outerRadius={145}
				innerRadius={85}
				data={graphData}
				startAngle={180}
				endAngle={0}
				style={{ top: 40 }}
				cx="50%"
				cy="75%"
			>
				<RadialBar
					background
					dataKey="value"
					legendType="circle"
					isAnimationActive={false}
				/>
				{isDataValid && (
					<Tooltip
						cursor={false}
						formatter={(index, value, payload) => [
							payload.payload.value,
							payload.payload.name,
						]}
						labelFormatter={() => null}
					/>
				)}
				<text x="50%" y="75%" fill="black" fontSize="2em" textAnchor="middle">
					{UtilityFunctions.convertDecimalToPercentage(data[0]?.value / data[1]?.value)}
				</text>
			</RadialBarChart>
		</ResponsiveContainer>
	);
};

export default PercentageGraph;
