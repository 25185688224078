import { QueryObject } from '@copilot/data/managers/base';
import { SorterResult } from 'antd/lib/table/interface';

export const addSorterToQuery = (sorter: SorterResult<any> | SorterResult<any>[], query: QueryObject) => {
	if (Array.isArray(sorter)) {
		// TODO: [AntdUpgrade] properly handle case where sorter is an array
		if (sorter.length > 0) {
			query.addSorter(((sorter[0].columnKey)?.toString() ?? ''), sorter[0].order === 'ascend' ? '' : '-');
		}
	} else if (sorter.columnKey) {
		query.addSorter((sorter.columnKey).toString(), sorter.order === 'ascend' ? '' : '-');
	}
};
