import {
	FlagOutlined,
	UnorderedListOutlined,
	ClockCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { Badge, Flex, Space, Spin, Switch, Tabs, Typography } from 'antd';
import InboxV2CardList from './listing/inboxV2CardList';
import InboxV2EmailList from './listing/inboxV2EmailList';
import styles from '../inboxPageV2.module.less';
import useInboxV2 from '../useInboxV2';
import { TAB_LOADER_TEST_ID } from '../constants';
import InboxV2ListSelectBox from './listing/inboxV2ListSelectBox';
import { SEARCH_CONTEXTS, SearchContext } from '../context/types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { NEW_MESSAGE_CARD, REMINDER_CARD } from './card/types';
import { InboxMessageType } from '../../inbox/data/types';

/**
 * Tab section for InboxV2, each tab has it's own listing strategy and implicit filters (eg Reminders vs New Messages)
 */
export default function InboxV2TabsSection() {
	const {
		messages,
		hasFilter,
		numUnreadMessages,
		numReminders,
		searchContext,
		onSearchTabChange,
		isTabLoading,
		sentimentFilterOptions,
		reminderFilterOptions,
		getRemindersContextFilter,
		getNewMessagesContextFilter,
		onRemindersContextFilterChange,
		onNewMessagesContextFilterChange,
		onAllMessagesContextFilterChange,
		hasMore,
		isLoading,
		onLoadMore,
		onViewConversation,
		onDeleteMessages,
		onMarkReminderAsComplete,
		onMarkAsRead,
	} = useInboxV2();
	const { ALL_MESSAGES_CONTEXT, UNREAD_MESSAGES_CONTEXT, REMINDERS_CONTEXT } = SEARCH_CONTEXTS;

	function getTabIcon(key: SearchContext) {
		if (isTabLoading(key)) {
			return (
				<Spin
					data-testid={TAB_LOADER_TEST_ID}
					indicator={<LoadingOutlined className={styles.tabSpinner} />}
				/>
			);
		}
		switch (key) {
			case ALL_MESSAGES_CONTEXT:
				return <UnorderedListOutlined />;
			case UNREAD_MESSAGES_CONTEXT:
				return <FlagOutlined />;
			case REMINDERS_CONTEXT:
				return <ClockCircleOutlined />;
			default:
				return null;
		}
	}

	return (
		<Tabs
			defaultActiveKey={searchContext}
			className={styles.tabsSection}
			onChange={onSearchTabChange}
			//prevents us from rendering non-visible tabs
			destroyInactiveTabPane
			items={[
				{
					key: ALL_MESSAGES_CONTEXT,
					label: (
						<Space>
							{getTabIcon(ALL_MESSAGES_CONTEXT)}
							All
						</Space>
					),
					children: (
						<InboxV2EmailList
							messages={messages}
							hasFilter={hasFilter}
							isLoading={isLoading}
							onReload={() => {}}
							canLoadMore={hasMore}
							isLoadingMore={false}
							onLoadMore={onLoadMore}
							onViewConversation={onViewConversation}
							isSelectable={true}
							extraHeaderContent={
								<Flex gap={10} align="center">
									<Switch onChange={onAllMessagesContextFilterChange} />
									<Typography.Text className={styles.listSwitchLabel}>
										View unreplied only
									</Typography.Text>
								</Flex>
							}
							onRemove={(msgs: InboxMessageType[]) =>
								onDeleteMessages(msgs.map((m) => m.threadId))
							}
						/>
					),
				},
				{
					key: UNREAD_MESSAGES_CONTEXT,
					label: (
						<Space>
							{getTabIcon(UNREAD_MESSAGES_CONTEXT)}
							Unread
							<Badge count={numUnreadMessages} />
						</Space>
					),
					children: (
						<InboxV2CardList
							messages={messages}
							hasFilter={hasFilter}
							extraHeaderContent={
								<InboxV2ListSelectBox
									options={sentimentFilterOptions}
									defaultValue={getNewMessagesContextFilter()}
									onChange={onNewMessagesContextFilterChange}
								/>
							}
							isLoading={isLoading}
							onReload={() => {}}
							canLoadMore={hasMore}
							isLoadingMore={isLoading && !isEmpty(messages)}
							onLoadMore={onLoadMore}
							onViewConversation={onViewConversation}
							onRemove={(msgs) => onMarkAsRead(msgs.map((m) => m.threadId))}
							cardType={NEW_MESSAGE_CARD}
						/>
					),
				},
				{
					key: REMINDERS_CONTEXT,
					label: (
						<Space>
							{getTabIcon(REMINDERS_CONTEXT)}
							Reminders
							<Badge count={numReminders} />
						</Space>
					),
					children: (
						<InboxV2CardList
							messages={messages.filter((m) => !isNil(m.reminder))}
							hasFilter={hasFilter}
							extraHeaderContent={
								<InboxV2ListSelectBox
									options={reminderFilterOptions}
									defaultValue={getRemindersContextFilter()}
									onChange={onRemindersContextFilterChange}
								/>
							}
							isLoading={isLoading}
							onReload={() => {}}
							canLoadMore={hasMore}
							isLoadingMore={false}
							onLoadMore={onLoadMore}
							onViewConversation={onViewConversation}
							onRemove={onMarkReminderAsComplete}
							cardType={REMINDER_CARD}
						/>
					),
				},
			]}
		/>
	);
}
