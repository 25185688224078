import { Select, SelectProps, Space } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { ReactNode, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { isArray, isUndefined } from 'lodash';
import DateRangeSelector from '../forms/common/generics/daterangeselector';
import { disableFutureDates } from '@copilot/common/utils';
import { ACTIVE_LABEL } from './constants';
import {
	CUSTOM_OPTION,
	DateRangeDropdownOption,
	DateRangeDropdownValue,
	OnChangeCallback,
} from './types';
import {
	TimeRangeSelectLabel,
	getDateRangeFromValue,
	isCustomOption,
	isCustomValue,
} from './util-functions';

type DateRangeDropdownProps = Omit<
	SelectProps<number, DateRangeDropdownOption>,
	'onChange' | 'value'
> &
Readonly<{
	iconPrefix?: ReactNode;
	onChange: OnChangeCallback;
	disableFutureDates?: boolean;
	value: DateRangeDropdownValue;
}>;

/**
 * Copilot DateRange dropdown
 * @param param0
 * @returns
 */
const DateRangeDropdown = ({
	iconPrefix = <CalendarOutlined />,
	options = [],
	optionLabelProp = ACTIVE_LABEL,
	onChange,
	value,
	disableFutureDates: areFutureDatesDisabled = true,
	...selectProps
}: DateRangeDropdownProps): JSX.Element => {
	const [datePickerDates, setDatePickerDates] = useState<{ start?: Moment; end?: Moment }>({});

	useEffect(() => {
		const { start, end } = datePickerDates;
		if (!isUndefined(start) && !isUndefined(end)) {
			onChange({ option: CUSTOM_OPTION, startDate: start, endDate: end });
		}
	}, [datePickerDates]);

	function onDropdownSelect(
		selectorValue: number,
		option: DateRangeDropdownOption | DateRangeDropdownOption[]
	): void {
		if (isArray(option)) {
			// Antd Select requires an array as a possible type for the change handler
			// We shouldn't reach this because we do not specify 'mode=multiple' in Select's props
			throw new Error('Multiple dateOptions were selected.');
		}

		if (isCustomOption(option)) {
			// We don't have defined start and end defined when the user first switches to custom
			onChange({ option, startDate: datePickerDates.start, endDate: datePickerDates.end });
		} else {
			// Get the date range for options with date values
			const { start, end } = getDateRangeFromValue(selectorValue);
			onChange({ option, startDate: start, endDate: end });
		}
	}

	function onDateSelectorChange(startDate?: Date, endDate?: Date): void {
		if (isCustomValue(value.value)) {
			setDatePickerDates({ start: moment(startDate), end: moment(endDate) });
		}
	}

	const isDateRangeShown = isUndefined(value) ? false : isCustomValue(value?.value);
	const disabledDate = areFutureDatesDisabled ? disableFutureDates : undefined;

	return (
		<Space>
			<Select
				optionLabelProp={optionLabelProp}
				{...selectProps}
				onChange={onDropdownSelect}
				value={value?.value}
			>
				{options.map((option) => {
					const optionProps = {
						key: option.key,
						value: option.value,
						[optionLabelProp]: (
							<TimeRangeSelectLabel icon={iconPrefix}>
								{option.label}
							</TimeRangeSelectLabel>
						),
					};

					return <Select.Option {...optionProps}>{option.label}</Select.Option>;
				})}
			</Select>
			{isDateRangeShown && (
				<DateRangeSelector
					datePickerProps={{
						value: [datePickerDates.start ?? null, datePickerDates.end ?? null],
						allowClear: false,
						allowEmpty: [false, false],
					}}
					onChange={onDateSelectorChange}
					disabledDate={disabledDate}
				/>
			)}
		</Space>
	);
};

export default DateRangeDropdown;
