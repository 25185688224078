import { CampaignType } from '../../../../core/data/responses/interface';

/**
 * Possible statuses for a campaign to exist in
 */
export const CampaignStatuses = {
  Enabled: 'Enabled',
  Disabled: 'Disabled',
  Unknown: 'Unknown',
} as const;

/**
 * The status of a campaign
 */
export type CampaignStatus = typeof CampaignStatuses[keyof typeof CampaignStatuses];

/**
 * The type for a campaign
 */
export type Campaign = Readonly<{
  id: string,
  name: string,
  type: CampaignType,
  status: CampaignStatus,
}>;
