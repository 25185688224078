import React, { useContext } from 'react';
import {
	Row, Col, Button,
} from 'antd';
import notificationManager from '@copilot/common/utils/notificationManager';
import { CampaignManager } from '@copilot/data';
import { ThemeContext } from 'styled-components';
import { disablePrevDates } from '@copilot/common/utils';
import moment, { Moment } from 'moment';
import { CopilotDatePicker } from '../input/dates';
//view types - campaign, sentiment, email

interface PauseSelectorProps {
	pausedTill: string | null;
	setPausedTill: (x: string | null) => void;
	orgMemberId: string;
}

const PauseSelector: React.FC<PauseSelectorProps> = (props) => {
	const { setPausedTill, orgMemberId, pausedTill } = props;
	const [isDatePickerShown, setDatePickerShown] = React.useState<boolean>(false);

	const pauseCampaign = React.useCallback(
		(date: string) => {
			if (!orgMemberId || orgMemberId == '') return undefined;
			return CampaignManager.pauseService(props.orgMemberId, date)
				.then((dateObj) => {
					if (dateObj?.unpauseDate != null) {
						setPausedTill(
							new Date(dateObj.unpauseDate)
								.toLocaleDateString('en-US', {
									weekday: 'long',
									month: 'long',
									day: 'numeric',
									year: 'numeric',
								})
								.replace(',', '')
						);
						notificationManager.showSuccessNotification({
							message: 'Saved',
							description: 'Your campaign is now paused',
						});
					} else if (!dateObj || dateObj.unpauseDate != null) {
						notificationManager.showErrorNotification({
							message: 'Failed',
							description: 'Please refresh the page and try again',
						});
					}
				})
				.catch((error) =>
					notificationManager.showErrorNotification({
						message: 'Failed',
						description: error.message,
					})
				);
		},
		[orgMemberId, setPausedTill]
	);

	const resumeCampaign = React.useCallback(() => {
		if (orgMemberId && orgMemberId !== '') {
			CampaignManager.unpauseService(orgMemberId).then(() => {
				setPausedTill(null);
				notificationManager.showSuccessNotification({
					message: 'Saved',
					description: 'Your campaign is now running',
				});
			});
		}
	}, [orgMemberId, setPausedTill]);

	const onDateChange = (date: Moment | string) => {
		const pauseDate = moment.isMoment(date) ? date.toISOString() : new Date(date).toISOString();
		pauseCampaign(pauseDate);
	};

	const toggleDatePicker = () => {
		setDatePickerShown(!isDatePickerShown);
	};

	const hideDatePicker = () => {
		setDatePickerShown(false);
	};

	const datePickerStatus = (status: any) => {
		if (!status) {
			hideDatePicker();
		}
	};

	const themeContext = useContext(ThemeContext);
	return (
		<>
			{!pausedTill && (
				<>
					<Row>
						<Col>
							{isDatePickerShown ? (
								<CopilotDatePicker
									style={{ height: '40px', width: '180px' }}
									open={isDatePickerShown}
									onOpenChange={datePickerStatus}
									onOk={onDateChange}
									showTime
									placeholder="Pause until"
									disabledDate={disablePrevDates}
								/>
							) :

								(
									<Button
										onClick={toggleDatePicker}
										style={{
											color: themeContext['@primary-color'],
											borderColor: themeContext['@primary-color'],
											height: '40px',
										}}
									>
										Pause All Campaign
									</Button>
								)}
						</Col>
					</Row>
				</>
			)}
			{pausedTill && props.children}
			{pausedTill && (
				<Row>
					<Col>
						<Button onClick={resumeCampaign} style={{ height: '40px' }}>
							Resume Campaign
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};
export default PauseSelector;
