import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import drawerManager from '@copilot/common/utils/drawerManager';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import type { Ref } from 'redux-orm';
import type { OrganizationMember } from '@copilot/common/store/models/redux';
import { useSelector } from 'react-redux';
import { PushNotificationSelectors } from '@copilot/common/store/selectors';
import { PushMethods } from '@copilot/data/responses/interface';

interface INotificationOptionProps {
	organizationId: string;
	title: string;
	notificationCode: string;
	isAdmin?: boolean;
	isOnByDefault?: boolean;
}

const NotificationOption: React.FC<INotificationOptionProps> = (props) => {
	const {
		title, notificationCode, isAdmin = false, organizationId, isOnByDefault = false,
	} = props;
	const [eligibleMembers, setEligibleMembers] = useState<readonly Ref<OrganizationMember>[]>([]);
	const members = useSelector(
		(state) => OrganizationMemberSelectors.getOrgMembersByOrgId(state, organizationId)
	);
	const adminMembers = useSelector(
		(state) => OrganizationMemberSelectors.getOwnerAndAdminsByOrgId(state, organizationId)
	);

	const subscriptionInfo = useSelector(
		(state) => PushNotificationSelectors.getNotificationByCode(state, notificationCode)
	);
	const subscribedMemberIds = useMemo(() => {
		if (!subscriptionInfo?.subscriptions) return [];
		return eligibleMembers.reduce<string[]>((acc, member) => {
			if (
				subscriptionInfo?.subscriptions[member.id] === PushMethods.Email ||
					(isOnByDefault && subscriptionInfo?.subscriptions[member.id] === undefined)
			) {
				acc.push(member.id);
			}
			return acc;
		}, []);
	}, [subscriptionInfo?.subscriptions, eligibleMembers]);

	useEffect(() => {
		setEligibleMembers(isAdmin ? adminMembers : members);
	}, [isAdmin, members, adminMembers]);

	const handleMembersUpdate = () =>
		drawerManager.openNotificationDrawer(
			{
				notificationCode,
				eligibleMembers,
				subscribedMemberIds,
				notificationLabel: title,
				closeAlert: false,
			}
		);

	const memberCounts = useMemo(() => {
		if (subscribedMemberIds.length === eligibleMembers.length) {
			return `All ${isAdmin ? 'Admin' : 'Members'}`;
		} else if (subscribedMemberIds.length === 0) {
			return 'No Members';
		} else {
			return `${subscribedMemberIds.length}/${eligibleMembers.length} Members`;
		}
	}, [subscribedMemberIds.length, eligibleMembers.length, isAdmin]);

	return (
		<Row style={{ paddingBottom: 10 }}>
			<Col span={4}>{title}</Col>
			<Col span={3}>
				<p style={{ paddingRight: 8, display: 'inline' }}>
					{memberCounts}
				</p>
			</Col>
			<Col span={6}>
				<a onClick={handleMembersUpdate}>Add/Remove Members</a>
			</Col>
		</Row>
	);
};

export default NotificationOption;
