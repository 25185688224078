import { MessageSentiments } from '@copilot/common/utils/constant/sentiments';
import lodash from 'lodash';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';

export const SentimentNameMap = (() => {
	switch (Config.agencyCode) {
		case AgencyCode.cleverly:
		case AgencyCode.clvwlbl:
			return {
				Interested: MessageSentiments.potentiallyInterested,
				NotInterested: MessageSentiments.notInterested,
				Maybe: MessageSentiments.potentiallyInterested,
				InProgress: MessageSentiments.ongoingConversations,
			};
		default:
			return {
				Interested: MessageSentiments.interested,
				NotInterested: MessageSentiments.notInterested,
				Maybe: MessageSentiments.maybe,
				InProgress: MessageSentiments.inProgress,
			};
	}
})();

export const Sentiments = lodash.uniq(Object.values(SentimentNameMap));

export type LinkedInStatLabelMapType = {
	LinkedInInvite: string;
	LinkedInMessage: string;
	LinkedInConnected: string;
	AddTag: string;
	UniqueReplies: string;
	SendMessage: string;
	ReplyInterested: string;
	ReplyNotInterested: string;
	ReplyMaybe: string;
	SendManualMsg: string;
	NurtureUniqueReplies: string;
	NurtureSendMessage: string;
	MovedConnection: string;
};

const DefaultStatLabel = {
	LinkedInInvite: 'Invites Sent',
	LinkedInMessage: 'Messages Sent',
	LinkedInConnected: 'New Connections',
	AddTag: 'Tags Added',
	UniqueReplies: 'Prospecting Replies',
	SendMessage: 'Messages Sent',
	ReplyInterested: 'Interested Replies',
	ReplyNotInterested: 'Not Interested Replies',
	ReplyMaybe: 'Maybe Replies',
	SendManualMsg: 'Manual Messages Sent',
	NurtureUniqueReplies: 'Nurture Replies',
	NurtureSendMessage: 'Nurture Messages Sent',
	MovedConnection: 'Connections Added',
};

export const LinkedInStatLabelMap: LinkedInStatLabelMapType = (() => {
	switch (Config.agencyCode) {
		case AgencyCode.cleverly:
		case AgencyCode.clvwlbl:
			return {
				...DefaultStatLabel,
				ReplyInterested: 'Positive Replies',
			};
		default:
			return DefaultStatLabel;
	}
})();

export const CampaignRowStatLabelMap = {
	ConnectionsOverInvites: 'Connection Rate %',
	ReplyOverConnections: 'Response Rate %',
	InterestedOverReply: 'Interested Rate %',
	ReplyOverInvites: 'Prospecting Reply Rate %',
	NurtureReplyOverSent: 'Response Rate %',
};

export enum IsoDayEnum {
	'Mon' = 1,
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
	'Sun'
}
