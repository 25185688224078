import React, { useCallback, useMemo, useState } from 'react';
import { LinkedInManager } from '@copilot/data';
import { useDispatch } from 'react-redux';
import { LinkedinActions } from '@copilot/common/store/actions/linkedin';
import { MemberLinkedInProfileResponse } from '@copilot/data/responses/interface';
import { LinkedInTaskManager } from '@copilot/data/managers/tasks';
import { useFetch, useTaskMonitor } from '../common';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';

export const useMemberLinkedinProfile = (orgId: string, memberId: string): MemberLinkedInProfileResponse | null | undefined => {
	const [profile, setProfile] = useState<MemberLinkedInProfileResponse | null>();
	const storeDispatch = useDispatch();

	React.useEffect(() => {
		orgId &&
			memberId &&
			LinkedInManager.getUserLinkedInProfile(orgId, memberId).then((usrProfile) => {
				setProfile(usrProfile.linkedInProfile);
				if (usrProfile.linkedInProfile)
					storeDispatch(
						LinkedinActions.loadMemberLinkedinProfile(usrProfile.linkedInProfile)
					);
			});
	}, [orgId, memberId]);

	return profile;
};

export const useContactLinkedinProfile = (orgId: string, memberId: string): MemberLinkedInProfileResponse | undefined => {
	const [profile, setProfile] = useState<MemberLinkedInProfileResponse>();
	const storeDispatch = useDispatch();

	React.useEffect(() => {
		if (orgId == '') return;
		orgId &&
			memberId &&
			LinkedInManager.getContactLinkedInProfile(orgId, memberId).then((contactProfile) => {
				setProfile(contactProfile);
				storeDispatch(LinkedinActions.loadMemberLinkedinProfile(contactProfile));
			});
	}, [orgId, memberId]);

	return profile;
};

const DefaultLoginErrorElement = (
	<span>
		Looks like there was an issue entering your info. Please try entering your credentials
		again, or contact us at
		{' '}
		{!Config.isAgency && (
			<a href="mailto:support@copilotai.com">support@copilotai.com</a>
		)}
		{Config.isAgency && Config.agencyCode === AgencyCode.cleverly && (
			<a href="mailto:support@cleverly.co">support@cleverly.co</a>
		)}
		{Config.isAgency && Config.agencyCode === AgencyCode.clvwlbl && (
			<a href="mailto:accounts@linkedinleadgen.co">accounts@linkedinleadgen.co</a>
		)}
		{Config.isAgency && Config.agencyCode === AgencyCode.prosocial && (
			<a href="mailto:accounts@prospectsocial.ly">accounts@prospectsocial.ly</a>
		)}
	</span>
);

type LinkedInLoginTask = {
	isFetching: boolean;
	status: boolean | undefined;
	issueCode: string | undefined;
	error: string | undefined
};

type LinkedInLogin = [
	/**
	 * function to submit linkedin login
	 */
	fetchLinkedInLogin: (userId: string, userName: string, password: string) => Promise<string>,
	/**
	 * function to submit linkedin verification pin
	 */
	submitVerificationPin: (pin: string) => Promise<boolean> | undefined,
	/**
	 * function to reset timeout timer on current task
	 */
	resetTimeoutTimer: () => void,
	/**
	 * Current logic task
	 */
	loginTask: LinkedInLoginTask
];

/**
 * Hook to handle LinkedIn Login
 * @param errorDisplay error message to display per errorType
 * @param onTimeOut callback on login task timeout
 * @returns {LinkedInLogin} functions and task for linkedin login
 */
export const useLinkedinLogin = (errorDisplay: { [errorType: string]: string }, onTimeOut?: () => void): LinkedInLogin => {
	const [linkedinLogin, fetchLinkedInLogin] = useFetch(LinkedInTaskManager.login);
	const [verificationPin, fetchVerificationPin] = useFetch(LinkedInTaskManager.submitVerificationPin);

	const taskId = useMemo(() => linkedinLogin.data, [linkedinLogin.data]);
	const [task, resetTimeoutTimer] = useTaskMonitor(taskId, undefined, undefined, undefined, onTimeOut);

	const submitVerificationPin = useCallback(
		 (pin: string) => {
			if (!taskId) return undefined;
			return fetchVerificationPin(taskId, pin);
		}, [taskId]);

	const isFetching = useMemo(() => linkedinLogin.isFetching || verificationPin.isFetching, [linkedinLogin.isFetching, verificationPin.isFetching]);
	const payload = useMemo(() =>
		task?.getPayload<{
			status: boolean;
			errors: { message: string; type: string }[];
		}>(), [task]);

	const error = useMemo(() =>
		(payload?.errors && payload.errors.length > 0 ?
			errorDisplay[payload.errors[0].type] ?? DefaultLoginErrorElement
			: undefined),
	 [payload?.errors]);

	const loginTask: LinkedInLoginTask = useMemo(() => ({
		isFetching,
		status: payload?.status,
		issueCode: task?.lastMessage.code,
		error,
	}), [isFetching, payload?.status, task?.lastMessage.code, error]);

	return [fetchLinkedInLogin, submitVerificationPin, resetTimeoutTimer, loginTask];
};
