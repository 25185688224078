import BasicContainer from '@copilot/common/components/containers/basic';
import { Alert, Button, Col, Divider, Popconfirm, Row, Space } from 'antd';
import TableModule from '../../tableModule';
import { FC, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ButtonProps } from 'antd/es/button';
import { Tag } from '@copilot/common/pages/organizationDashboard/tags/data/models';
import { TableModuleTableProps } from '../../tableModule/types';
import {
	TableModuleColumn,
	TableModuleHeader,
	TableModuleStandardTitle,
} from '../../tableModule/util';
import { TableModuleTable } from '../../tableModule/table';

const renderTagName = (tagName: string) => tagName;

const popConfirmButtonSize: ButtonProps = { size: 'small' };

/**
 * [Presentational] Tiny component to display offset text
 * @param {string} text
 */
const ButtonText = ({ text }: { text: string }) => (
	<span style={{ transform: 'translateY(-1px)' }}>{text}</span>
);

interface ColumnActionsProps {
	onUpdateTag: (oldTag: Tag) => Promise<void>;
	onDeleteTag: (tag: Tag) => void;
	record: Tag;
	isDisabled: Record<string, boolean>;
	setDisabled: (arg: Record<string, boolean>) => unknown;
}

/**
 * [Presentational] Component displaying possible actions for a given tag
 * @param {ColumnActionsProps} props
 */
const ColumnActions: FC<ColumnActionsProps> = (props) => {
	const { onUpdateTag, onDeleteTag, record, isDisabled, setDisabled } = props;

	const handleConfirmDeleteTag = () => {
		setDisabled({ ...isDisabled, [record.id]: true });
		try {
			onDeleteTag(record);
		} catch {
			// ignore failed deletion and re-enable delete button
		}
		setDisabled({ ...isDisabled, [record.id]: false });
	};

	return (
		<Space size={20}>
			<Button
				size="small"
				type="link"
				onClick={() => {
					onUpdateTag(record);
				}}
			>
				Rename
			</Button>
			<Popconfirm
				placement="topLeft"
				title={
					<div style={{ color: 'black' }}>
						<div>Are you sure you want to delete this tag?</div>
						<div>Deleting a tag is irreversible.</div>
					</div>
				}
				icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
				onConfirm={handleConfirmDeleteTag}
				okText={<ButtonText text="Delete" />}
				okButtonProps={popConfirmButtonSize}
				cancelText={<ButtonText text="Cancel" />}
				cancelButtonProps={popConfirmButtonSize}
				disabled={isDisabled[record.id]}
			>
				<Button size="small" type="link" danger>
					Delete
				</Button>
			</Popconfirm>
		</Space>
	);
};

interface OrganizationTagTableProps extends TableModuleTableProps<Tag> {
	tags: Tag[];
	onCreateTag: () => Promise<void>;
	onUpdateTag: (oldTag: Tag) => Promise<void>;
	onDeleteTag: (tag: Tag) => void;
}

/**
 * [Presentational] Table to show all tags in the organization
 * @param {OrganizationTagTableProps} props
 */
const OrganizationTagsTable: FC<OrganizationTagTableProps> = (props) => {
	const [isDisabled, setDisabled] = useState<Record<string, boolean>>({});
	const { loading, tags, onCreateTag, onUpdateTag, onDeleteTag } = props;

	return (
		<BasicContainer>
			<BasicContainer.Header>
				Manage tags easily within your organization.
			</BasicContainer.Header>
			<BasicContainer.Content>
				<Row>
					<p style={{ marginBottom: 0 }}>
						Use tags to track where your leads are in the funnel (e.g. Meeting Booked)
						or add additional qualifiers (e.g. Decision Maker).
					</p>
				</Row>
				<Row>
					<p>
						We prepopulate the following tags to your connections that aren’t editable,
						so they will not show up in the table below:
					</p>
				</Row>
				<Row>
					<ul>
						<li>
							Funnels are tagged as “Replied” and “Message 1", “Message 2”, and so
							forth;
						</li>
						<li>
							The prospect’s first reply sentiment is tagged as: “Interested First
							Reply”, “Maybe” or “Not Interested”.
						</li>
					</ul>
				</Row>
				<Row>
					<Alert
						message="The changes you make to these tags will be applied across your organization"
						type="warning"
					/>
				</Row>
				<Divider type="horizontal" />
				<Row>
					<Col span={24}>
						<TableModule
							header={
								<TableModuleHeader>
									<Row>
										<Col span={4}>
											<TableModuleStandardTitle>
												<span>Total Tags: {tags.length}</span>
											</TableModuleStandardTitle>
										</Col>
										<Col span={16}></Col>
										<Col span={4}>
											<span style={{ display: 'block', textAlign: 'right' }}>
												<Button
													type="primary"
													onClick={() => {
														onCreateTag();
													}}
												>
													Create Tag
												</Button>
											</span>
										</Col>
									</Row>
								</TableModuleHeader>
							}
							table={
								<TableModuleTable loading={loading} rowKey="id" dataSource={tags}>
									<TableModuleColumn
										title="Tag Name"
										columnKey="tagName"
										dataIndex="name"
										render={renderTagName}
										width="40%"
										sorter={(a: Tag, b: Tag) => a.name.localeCompare(b.name)}
										sortDirections={['ascend', 'descend']}
										showSorterTooltip={false}
									/>
									<TableModuleColumn
										title="Actions"
										columnKey="actions"
										dataIndex="id"
										render={(value, record: Tag) => (
											<ColumnActions
												onUpdateTag={onUpdateTag}
												onDeleteTag={onDeleteTag}
												record={record}
												isDisabled={isDisabled}
												setDisabled={setDisabled}
											/>
										)}
										width="60%"
									/>
								</TableModuleTable>
							}
						/>
					</Col>
				</Row>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default OrganizationTagsTable;
