import { useMemo } from 'react';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';

const getDiscriptionContent = () => {
	if (!Config.isAgency) {
		const practices = !Config.clickAndSendSupportURL ?
			{
				header: 'Best practices for creating quick responses to get more positive responses:',
				body: [
					'Keep it short (5 sentences max)',
					'Keep it clear (if you confuse prospects you lose them)',
					'Keep it conversational',
					'Add a call to action',
					'Add a discovery question',
					'Add your calendar link',
					'Ask for prospect\'s email address or phone number',
				],
			}
			:
			{
				header: 'Best practices for creating quick responses to get more positive responses:',
				body: [],
			};
		const useCases = !Config.clickAndSendSupportURL ?
			{
				header: 'Best use cases for creating quick responses:',
				body: [
					'Prospect is interested at a later date',
					'Prospect is interested now',
					'Prospect is not interested',
					'Prospect is not the right person',
					'Prospect is neutral',
				],
			}
			:
			{
				header: '',
				body: [],
			};
		return {
			Introduction: `
			Set up response templates for your most common replies! Once set up,
			these "Quick Response" messages will be saved in your inbox, making it
			easy to click and send your proven responses to prospects in seconds
			flat. (You can proof and edit before sending too!)
		`,
			Practices: practices,
			UseCases: useCases,
		};
	}

	switch (Config.agencyCode) {
		case AgencyCode.cleverly:
		case AgencyCode.clvwlbl:
		case AgencyCode.prosocial:
			return {
				Introduction: `
					Set up response templates for your most common replies!
					Once set up, these "Quick Response" messages will be saved in your inbox,
					making it easy to click and send your proven responses to prospects
					in a few seconds. You can always edit before sending.
				`,
				Practices: {
					header: 'Best practices:',
					body: [
						'Keep templates short and conversational',
						'Ask for emails and numbers',
						'Always include clear next steps',
						'Include your calendar link',
					],
				},
				UseCases: {
					header: 'Best use cases:',
					body: [
						'Interested later',
						'Interested now',
						'Not interested',
						'Neutral response',
					],
				},
			};
		default:
			throw TypeError('Description not found');
	}
};

export const TemplateDescription = () => {
	const content = useMemo(() => getDiscriptionContent(), [Config.agencyCode, Config.isAgency, Config.clickAndSendSupportURL]);
	return (
		<>
			<p>
				{content.Introduction}
			</p>
			<p>
				{content.Practices.header}
				{' '}
				{!Config.isAgency && !!Config.clickAndSendSupportURL && <a href={Config.clickAndSendSupportURL} target="_blank">Help and Support Portal</a>}
				<ul>
					{content.Practices.body.map((point) => <li>{point}</li>)}
				</ul>
			</p>
			<p>
				{content.UseCases.header}
				<ul>
					{content.UseCases.body.map((point) => <li>{point}</li>)}
				</ul>
			</p>
		</>
	);
};
