/**
 * Message events for OAuth
 */
export const OAuthConstants = {
    OAuthStateKey: 'oauth2-state-key',
    OAuthResponse: 'oauth2-response'
} as const;

/**
 * Dimensions of the OAuth popup window
 */
export const OAuthPopupDimentions = {
    Height: 700,
    Width: 550
} as const;

/**
 * OAuth response types
 */
export const OAuthResponseTypes = {
    Code: 'code',
    Token: 'token'
} as const;