import Input from 'antd/lib/input/Input';
import Search from 'antd/lib/input/Search';
import useInboxV2 from '../useInboxV2';
import styles from '../inboxPageV2.module.less';

type InboxV2SearchBarProps = {
	searchPlaceholder?: string;
	/**outlet for an extra piece of JSX we would like to display beside the search bar (eg a dropdown) */
	extraContent?: React.ReactNode;
};

/**
 * InboxV2SearchBar, supports manual search and debounced search (debounce is being used to demo how we can achieve config options with hooks + context)
 */
export default function InboxV2SearchBar({
	searchPlaceholder = 'Search',
	extraContent,
}: InboxV2SearchBarProps) {
	const {
		onSearchTermChange,
		onDebouncedSearchTermChange,
		onSubmitSearch,
		contextSearchTerm,
		useDebounceSearch,
	} = useInboxV2();

	return (
		<div className={styles.searchBarWrapper}>
			{useDebounceSearch ? (
				<Input
					className={styles.searchBar}
					defaultValue={contextSearchTerm}
					placeholder={searchPlaceholder}
					onChange={(e) => onDebouncedSearchTermChange(e.target.value)}
				/>
			) : (
				<Search
					className={styles.searchBar}
					defaultValue={contextSearchTerm}
					placeholder={searchPlaceholder}
					onChange={(e) => onSearchTermChange(e.target.value)}
					onSearch={onSubmitSearch}
				/>
			)}

			{extraContent}
		</div>
	);
}
