import { useMemo, useCallback, useEffect } from 'react';
import { AttentionItem, OrgMemberAttentionItemModel } from '@copilot/common/utils/attentionItem/models';
import { AttentionItemLevel, AttentionItemType } from '@copilot/common/utils/attentionItem/constant';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { useHistory } from 'react-router';
import { AttentionItemTrackingErrorType } from '@copilot/common/utils/attentionItem/tracking';
import { IAttentionRequiredCardOrgMembers, IAttentionRequiredCardItem } from '@copilot/common/components/card/attentionRequiredCard';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import { formatNameFromObject } from '@copilot/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { createBaseUserCampaignAttentionItemsSelector, createCampaignAttentionItemsSelector } from '@copilot/common/utils/attentionItem/selector';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { Config } from '@copilot/common/config';
import { useFeatureToggle } from '../feature';
import { Features } from '@copilot/data/responses/interface';
import { AttentionItemManager } from '@copilot/data';
import { LoadAttentionItemsAction } from '@copilot/common/utils/attentionItem/saga';
import { OrganizationDashboardTabKeys } from '@copilot/common/utils/constant';

/**
 * Hook that provides a function that converts orgMemberAttentionItems to be used in the Attention Required card
 * @param {boolean} isTeamAdmin whether the user is a team admin
 * @param {boolean} isClickable whether the links should be clickable, when applicable
 * @param {function} updateTracking function to update tracking
 * @param {Record<string, string>} campaignIdNameMap map of campaign ids to the campaign name
 */
export const useAttentionItemConverter = (
	isTeamAdmin: boolean,
	isClickable: boolean,
	campaignIdNameMap: Record<string, string>,
	updateTracking: (errorType: AttentionItemTrackingErrorType) => void
): (orgMemberItems: OrgMemberAttentionItemModel) => IAttentionRequiredCardOrgMembers => {
	const history = useHistory();

	//#region - Redirects

	// handles actions that are called after all redirects
	const onRedirect = useCallback((trackingType: AttentionItemTrackingErrorType) => {
		updateTracking(trackingType);
	}, [updateTracking]);

	// handles onClick with a redirect to the organization team members tab
	const onClickRedirectOrgTeamMembersTab = useCallback((orgMemberId: string, trackingType: AttentionItemTrackingErrorType) => (
		(isClickable)
			? () => {
				history.push('/?tab=teammembers', { teamMembersRedirectMemberId: orgMemberId });
				onRedirect(trackingType);
			} : undefined
	), [isClickable, history, onRedirect]);

	// handles onClick with a redirect to the linkedin settings page
	const onClickRedirectLinkedInSettingsPage = useCallback((trackingType: AttentionItemTrackingErrorType) => (
		(isClickable)
			? () => {
				history.push('/settings/linkedin');
				onRedirect(trackingType);
			} : undefined
	), [isClickable, history, onRedirect]);

	// handles onClick with a redirect to a campaign dashboard tab
	const onClickRedirectCampaign = useCallback((tabName: CampaignDashboardTabKeys, campaignId: string | null, campaignMemberId: string | null, trackingType: AttentionItemTrackingErrorType) => (
		(isClickable && campaignId && campaignMemberId)
			? () => {
				history.push(`/campaign/${campaignId}?tab=${tabName}`, {
					teamMembersRedirectMemberId: (tabName === CampaignDashboardTabKeys.TeamMembers) ? campaignMemberId : undefined,
					searchListRedirectMemberId: (tabName === CampaignDashboardTabKeys.SearchList || tabName === CampaignDashboardTabKeys.NurtureList) ? campaignMemberId : undefined,
				});
				onRedirect(trackingType);
			} : undefined
	), [isClickable, history, onRedirect]);

	//#endregion

	//#region - Error Handling

	const handleLoggedOutOfLinkedIn = useCallback((attentionItem: AttentionItem): IAttentionRequiredCardItem => {
		let label = '';
		let onClick;
		if (isTeamAdmin) {
			label = 'LinkedIn account disconnected';
			onClick = onClickRedirectOrgTeamMembersTab(attentionItem.orgMemberId, AttentionItemTrackingErrorType.BlockerLoggedOut);
		} else {
			label = 'Your LinkedIn account is disconnected';
			onClick = onClickRedirectLinkedInSettingsPage(AttentionItemTrackingErrorType.BlockerLoggedOut);
		}
		return ({ label, level: attentionItem.level, onClick });
	}, [isTeamAdmin, onClickRedirectOrgTeamMembersTab, onClickRedirectLinkedInSettingsPage]);

	const handleCampaignSearchListLow = useCallback((attentionItem: AttentionItem, campaignName: string): IAttentionRequiredCardItem => {
		let label = '';
		let onClick;
		if (attentionItem.level === AttentionItemLevel.Blocker) {
			label = isTeamAdmin
				? `Search list is empty in ${campaignName}`
				: 'Your search list is empty in this campaign';
			onClick = onClickRedirectCampaign(CampaignDashboardTabKeys.SearchList, attentionItem.campaignId, attentionItem.campaignMemberId, AttentionItemTrackingErrorType.BlockerEmptySearchList);
		} else {
			label = isTeamAdmin
				? `Search list in ${campaignName} is running low and a new search list will be needed soon.`
				: 'Your search list in this campaign is going to run out soon. A new one will be needed.';
			onClick = onClickRedirectCampaign(CampaignDashboardTabKeys.SearchList, attentionItem.campaignId, attentionItem.campaignMemberId, AttentionItemTrackingErrorType.WarningLowSearchList);
		}
		return ({ label, level: attentionItem.level, onClick });
	}, [isTeamAdmin, onClickRedirectCampaign]);

	const handleNoInvitesSentForProspectingCampaign = useCallback((attentionItem: AttentionItem, campaignName: string): IAttentionRequiredCardItem => {
		let label = '';
		if (attentionItem.level === AttentionItemLevel.Blocker) {
			label = isTeamAdmin
				? `No new invites sent over multiple days in ${campaignName}`
				: 'There have been no new invites sent over multiple days in this campaign';
		} else {
			label = isTeamAdmin
				? `No new invites sent recently in ${campaignName}`
				: 'You have sent no new invites recently in this campaign';
		}
		return ({ label, level: attentionItem.level });
	}, [isTeamAdmin]);

	const handleNoMessagesSentForNurtureCampaign = useCallback((attentionItem: AttentionItem, campaignName: string): IAttentionRequiredCardItem => {
		let label = '';
		if (attentionItem.level === AttentionItemLevel.Blocker) {
			label = isTeamAdmin
				? `No new automated messages sent over multiple days in ${campaignName}`
				: 'There have been no new automated messages sent over multiple days in this campaign';
		} else {
			label = isTeamAdmin
				? `No new automated messages sent recently in ${campaignName}`
				: 'You have sent no new automated messages recently in this campaign';
		}
		return ({ label, level: attentionItem.level });
	}, [isTeamAdmin]);

	const handleNoInvitesSetForProspectingCampaign = useCallback((attentionItem: AttentionItem, campaignName: string): IAttentionRequiredCardItem => {
		const label = isTeamAdmin
			? `No invites set in ${campaignName}. The campaign will not run until they are set.`
			: 'You have no invites set in this campaign';
		const onClick = onClickRedirectCampaign(CampaignDashboardTabKeys.TeamMembers, attentionItem.campaignId, attentionItem.campaignMemberId, AttentionItemTrackingErrorType.WarningNoInvitesMessagesSet);
		return ({ label, level: attentionItem.level, onClick });
	}, [isTeamAdmin, onClickRedirectCampaign]);

	const handleNoMessagesSetForNurtureCampaign = useCallback((attentionItem: AttentionItem, campaignName): IAttentionRequiredCardItem => {
		const label = isTeamAdmin
			? `No messages set in ${campaignName}. The campaign will not run until they are set.`
			: 'You have no messages set in this campaign';
		const onClick = onClickRedirectCampaign(CampaignDashboardTabKeys.TeamMembers, attentionItem.campaignId, attentionItem.campaignMemberId, AttentionItemTrackingErrorType.WarningNoInvitesMessagesSet);
		return ({ label, level: attentionItem.level, onClick });
	}, [isTeamAdmin, onClickRedirectCampaign]);

	const handleAllocatedInvitesForCampaignsOverLimit = useCallback((attentionItem: AttentionItem): IAttentionRequiredCardItem => {
		const label = isTeamAdmin
			? 'Invite usage is above recommended 25 invites per day'
			: 'Your invite usage is above recommended 25 invites per day';
		return ({ label, level: attentionItem.level });
	}, [isTeamAdmin]);

	//#endregion

	// converts an AttentionItem into an IAttentionRequiredCardItem to be used in the AttentionRequiredCard component
	const convertAttentionItem = (attentionItem: AttentionItem): IAttentionRequiredCardItem => {
		const campaignName = `"${campaignIdNameMap[attentionItem.campaignId ?? ''] ?? 'campaign'}"`;
		switch (attentionItem.type) {
			case AttentionItemType.LoggedOutOfLinkedIn:
				return handleLoggedOutOfLinkedIn(attentionItem);
			case AttentionItemType.CampaignSearchListLow:
				return handleCampaignSearchListLow(attentionItem, campaignName);
			case AttentionItemType.NoInvitesSentForProspectingCampaign:
				return handleNoInvitesSentForProspectingCampaign(attentionItem, campaignName);
			case AttentionItemType.NoMessagesSentForNurtureCampaign:
				return handleNoMessagesSentForNurtureCampaign(attentionItem, campaignName);
			case AttentionItemType.NoInvitesSetForProspectingCampaign:
				return handleNoInvitesSetForProspectingCampaign(attentionItem, campaignName);
			case AttentionItemType.NoMessagesSetForNurtureCampaign:
				return handleNoMessagesSetForNurtureCampaign(attentionItem, campaignName);
			case AttentionItemType.AllocatedInvitesForCampaignsOverLimit:
				return handleAllocatedInvitesForCampaignsOverLimit(attentionItem);
			default:
				return UtilityFunctions.assertUnreachable(attentionItem.type);
		}
	};

	// converts an OrgMemberAttentionItemModel into an IAttentionRequiredCardOrgMembers to be used in the AttentionRequiredCard component
	const convertOrgMemberAttentionItem = (orgMemberItems: OrgMemberAttentionItemModel) => ({
		orgMemberId: orgMemberItems.id,
		orgMemberDisplayName: formatNameFromObject(orgMemberItems.user),
		attentionItems: orgMemberItems.attentionItems.map((item) => convertAttentionItem(item)),
	});

	return convertOrgMemberAttentionItem;
};

/**
 * Hook to grab the attention items for a specific campaign
 * @param {string} campaignId the id of the current campaign
 * @param {string | undefined} orgRoleTitle the type of the current user, default to Base user to restrict visible errors
 * @param {function} convertOrgMemberAttentionItem  converts the retrieved attention items to a form that can be used in the Attention Required Card
 */
export const useCampaignAttentionItemsSelector = (
	campaignId: string,
	convertOrgMemberAttentionItem: (orgMemberItems: OrgMemberAttentionItemModel) => IAttentionRequiredCardOrgMembers,
	orgRoleTitle: string = OrgRoleTitles.User
): IAttentionRequiredCardOrgMembers[] => {
	// Note: wrapping the selector in useMemo prevents unnecessary selector updates
	const attentionItemsSelector = useMemo(() => (
		orgRoleTitle === OrgRoleTitles.User
			? createBaseUserCampaignAttentionItemsSelector(campaignId)
			: createCampaignAttentionItemsSelector(campaignId)
	), [campaignId, orgRoleTitle]);

	const attentionItems = useSelector(attentionItemsSelector);
	const attentionItemsByMember = useMemo(() => (
		attentionItems?.map((member) => convertOrgMemberAttentionItem(member)) ?? []
	), [convertOrgMemberAttentionItem, attentionItems]);

	return attentionItemsByMember;
};

/**
 * Hook to handle the onClick behaviour for the help button on the Attention Required component
 * @param {function} updateTracking function to update the tracking
 */
export const useAttentionRequiredHelpClick = (updateTracking: (errorType: AttentionItemTrackingErrorType) => void): (() => void) | undefined => {
	const onAttentionRequiredHelpClick = () => {
		window.open(Config.attentionRequiredHelpURL);
		updateTracking(AttentionItemTrackingErrorType.HelpArticleButton);
	};
	return Config.attentionRequiredHelpURL ? onAttentionRequiredHelpClick : undefined;
};

/**
 * Hook to handle fetching attention items for an organization whenever the Organization Dashboard tab changes
 * @param {string} organizationId the id of the organization we want to fetch for
 * @param {string} tabKey the key of the current tab to check for
 */
export const useFetchOrgDashAttentionItems = (organizationId: string, tabKey: string) => {
	const dispatch = useDispatch();
	const isTeamsAttentionRequiredFeatureEnabled = useFeatureToggle(Features.TeamsAttentionRequiredFeature);

	const fetchAttentionItems = useCallback(() => {
		// make sure organizationId isn't an empty string
		if (isTeamsAttentionRequiredFeatureEnabled && organizationId) {
			dispatch(LoadAttentionItemsAction(AttentionItemManager.getOrgAttentionItems, organizationId));
		}
	}, [isTeamsAttentionRequiredFeatureEnabled, organizationId]);

	useEffect(() => {
		// fetch attention items when we go to the Summary tab of the Organization Dashboard
		if (tabKey === OrganizationDashboardTabKeys.Summary) fetchAttentionItems();
	}, [tabKey, fetchAttentionItems]);
};
