import { JSX, useState } from 'react';
import { EmailListItemProps, LIST_ITEM_TOOLTIPS } from './types';
import styles from './emailItem.module.less';
import { Badge, Button, Divider, Popconfirm, Typography } from 'antd';
import { DeleteOutlined, LinkedinFilled } from '@ant-design/icons';
import {
	EMAIL_ITEM_CONTAINER_TEST_ID,
	EMAIL_ITEM_DELETE_TEST_ID,
	EMAIL_ITEM_LINKEDIN_TEST_ID,
	EMAIL_ITEM_REMINDER_TEST_ID,
} from './constants';
import { ContactAvatar } from '../../../../components/componentModels/activities/conversationWrapper/item/contact-avatar';
import DropdownReminderSelector from '../../../../components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import ButtonWithTooltip from '../../../../components/selector/reminder/dropdownSelector/buttonToolTip';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const { Text } = Typography;

/**
 * [Presentational] Component used to display a message from a prospect in the inbox
 */
export function EmailItem({
	contactName,
	profileUrl,
	message,
	timestamp,
	isRead,
	isSelected,
	isDeclined,
	onClick,
	onDelete: handleDelete,
	reminder: {
		hasReminder: initReminderState,
		onCancelReminder: handleReminderCancelled,
		onSetReminder: handleReminderSet,
		onReminderClicked,
		reminderTimestamp,
	},
}: EmailListItemProps): JSX.Element {
	const [isReminderLoading, setIsReminderLoading] = useState(false);
	const [hasReminder, setHasReminder] = useState(initReminderState);
	const [isDeleting, setIsDeleting] = useState(false);

	async function onCancelReminder() {
		setIsReminderLoading(true);
		await handleReminderCancelled();
		setHasReminder(false);
		setIsReminderLoading(false);
	}

	async function onSetReminder(date: moment.Moment, isNewReminder: boolean) {
		setIsReminderLoading(true);
		await handleReminderSet(date, isNewReminder);
		setHasReminder(true);
		setIsReminderLoading(false);
	}

	async function onDelete() {
		setIsDeleting(true);
		await handleDelete();
		setIsDeleting(false);
	}

	return (
		<div
			onClick={onClick}
			className={[styles.emailItem, isSelected ? styles.selected : ''].join(' ')}
			data-testid={EMAIL_ITEM_CONTAINER_TEST_ID}
		>
			<div className={styles.messageWrapper}>
				<ContactAvatar type="inbox" contactName={contactName} />

				<div className={styles.messageContentWrapper}>
					<Text className={styles.name} title={contactName} strong>
						{contactName}
					</Text>
					<Divider type="vertical" className={styles.divider} />
					<Text
						className={[styles.message, isRead ? styles.read : ''].join(' ')}
						strong={!isRead}
						title={message}
					>
						{isDeclined ? `Declined - ${message}` : message}
					</Text>
				</div>
			</div>

			<div className={styles.actionWrapper} onClick={(event) => event.stopPropagation()}>
				<Text className={styles.timestamp}>{timestamp}</Text>

				<ButtonWithTooltip
					isIconOnly
					label={
						isEmpty(profileUrl)
							? LIST_ITEM_TOOLTIPS.LINKED_IN_NOT_ACCESSIBLE
							: LIST_ITEM_TOOLTIPS.VIEW_LINKEDIN
					}
					size="middle"
					type="text"
					icon={<LinkedinFilled />}
					disabled={isEmpty(profileUrl)}
					href={profileUrl}
					target="_blank"
					referrerPolicy="no-referrer"
					data-testid={EMAIL_ITEM_LINKEDIN_TEST_ID}
				/>

				<Badge dot={hasReminder} data-testid={EMAIL_ITEM_REMINDER_TEST_ID}>
					{/* TODO > Populate reminder related functionality when list logic exists */}
					<DropdownReminderSelector
						buttonType="text"
						isIconOnly={true}
						isReminderSet={hasReminder}
						onCancel={() => void onCancelReminder()}
						onUpdate={(date, isNewReminder) => void onSetReminder(date, isNewReminder)}
						onSetReminderClick={onReminderClicked}
						loading={isReminderLoading}
						selectedDate={hasReminder ? moment(reminderTimestamp) : undefined}
					/>
				</Badge>

				<Popconfirm
					title={
						<>
							<Text>
								Deleting this message will remove it from your inbox completely.
							</Text>
							<br />
							<Text>You can still find it in Connections.</Text>
						</>
					}
					placement={'bottomRight'}
					okText="Delete"
					onConfirm={() => void onDelete()}
				>
					<Button
						type="text"
						icon={<DeleteOutlined />}
						loading={isDeleting}
						data-testid={EMAIL_ITEM_DELETE_TEST_ID}
					/>
				</Popconfirm>
			</div>
		</div>
	);
}
