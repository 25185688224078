import { Button, Flex, Table } from 'antd';
import { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Meeting, PaginatedMeetingResponse } from '../types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TablePaginationConfig } from 'antd/lib';
import styles from './meetingTables.module.less';
import debounce from 'lodash/debounce';
import { MIN_TABLE_WIDTH, VIRTUAL_SCROLL_OFFSET } from './constants';
import { isNil } from 'lodash';
import notificationManager from '@copilot/common/utils/notificationManager';

type BaseMeetingTableProps = {
	enablePersonalizedInsights?: boolean;
	leadingColumn: ColumnType<Meeting>;
	data: PaginatedMeetingResponse;
	onPageChange: (targetPage: number, pageSize: number) => void;
	onViewConversation: (meeting: Meeting, idx: number) => Promise<void>;
	onViewPersonalizedInsights?: (meeting: Meeting) => Promise<void>;
	onPageSizeChange: (pageSize: number) => void;
	pageSize: number;
	isExternallyLoading?: boolean;
	viewPortHeight: number;
};
/**
 * Base meetingsBookedTable, contains common elements and logic shared between detected and confirmed meeting tables
 * @returns
 */
export default function BaseMeetingsTable({
	leadingColumn,
	enablePersonalizedInsights = false,
	data: externalData,
	onPageChange,
	onViewConversation,
	onViewPersonalizedInsights: handleViewPersonalizedInsights,
	onPageSizeChange,
	pageSize,
	isExternallyLoading = false,
	viewPortHeight,
}: BaseMeetingTableProps) {
	const [dimensions, setDimensions] = useState({ x: 0, y: 0 });
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [loadingPersonalizedInsightsId, setLoadingPersonalizedInsightsId] = useState('');
	const [data, setData] = useState(externalData);

	//slightly buffer the data updates so our table doesn't flicker between empty -> loaded all the time
	useEffect(() => {
		if (!isExternallyLoading) {
			setData(externalData);
		}
	}, [externalData]);

	useEffect(() => {
		updateDimensions();
	}, [viewPortHeight]);

	/**
	 * Handler for adjusting the dimensions for the table, needed to support virtual scrolling
	 */
	function updateDimensions() {
		if (wrapperRef.current) {
			const { width, height } = wrapperRef.current?.getBoundingClientRect();
			setDimensions({
				x: Math.max(width, MIN_TABLE_WIDTH),
				y: Math.max(height - VIRTUAL_SCROLL_OFFSET, 0),
			});
		}
	}

	/**
	 * Debounced version of dimension update function
	 */
	const debouncedUpdateDimension = useCallback(debounce(updateDimensions, 200), [
		updateDimensions,
	]);

	useEffect(() => {
		// Update dimensions on window resize
		window.addEventListener('resize', debouncedUpdateDimension);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', debouncedUpdateDimension);
		};
	}, []);

	/**
	 * Catch all handler for table changes (filters, pages, page sizes, sorts)
	 * @param pagination
	 */
	function onTableChange(pagination: TablePaginationConfig) {
		if (pageSize !== pagination?.pageSize && typeof pagination?.pageSize === 'number') {
			onPageSizeChange(pagination.pageSize);
			onPageChange(1, pagination.pageSize);
		} else {
			onPageChange(pagination.current ?? 1, pageSize);
		}
	}

	function onViewPersonalizedInsights(meeting: Meeting) {
		if (!isNil(handleViewPersonalizedInsights)) {
			setLoadingPersonalizedInsightsId(meeting.id);
			handleViewPersonalizedInsights(meeting)
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'There was an issue viewing personalized insights',
					});
				})
				.finally(() => {
					setLoadingPersonalizedInsightsId('');
				});
		}
	}

	return (
		<div className={styles.tableWrapper} ref={wrapperRef}>
			<Table
				dataSource={data.content}
				loading={{
					spinning: isExternallyLoading,
					indicator: <LoadingOutlined className={styles.tableLoader} />,
				}}
				virtual
				scroll={dimensions}
				pagination={{
					pageSize,
					total: data.count,
					current: data.pages.current + 1,
				}}
				onRow={(record: Meeting, idx: number | undefined) => {
					return {
						onClick: () => {
							onViewConversation(record, idx ?? 0);
						},
					};
				}}
				onChange={(p) => void onTableChange(p)}
				columns={[
					leadingColumn,
					{
						title: 'Name',
						dataIndex: 'name',
						render: (name) => (
							<div className={styles.cellTextWrapper} title={name}>
								{name}
							</div>
						),
						width: '20%',
					},

					{
						title: 'Job title',
						dataIndex: 'title',
						render: (jobTitle) => (
							<div className={styles.cellTextWrapper} title={jobTitle}>
								{jobTitle}
							</div>
						),
						width: '20%',
					},
					{
						title: 'Company',
						dataIndex: 'company',
						render: (company) => (
							<div className={styles.cellTextWrapper} title={company}>
								{company}
							</div>
						),
						width: '20%',
					},
					{
						title: '',
						dataIndex: '',
						render: (meeting: Meeting) => (
							<Flex gap={8} justify="flex-end" className={styles.actionWrapper}>
								{enablePersonalizedInsights && (
									<Button
										loading={loadingPersonalizedInsightsId === meeting.id}
										onClick={(e) => {
											e.stopPropagation();
											onViewPersonalizedInsights?.(meeting);
										}}
									>
										Personalized insights
									</Button>
								)}
								<RightOutlined />
							</Flex>
						),
						width: '20%',
					},
				]}
			/>
		</div>
	);
}
