import React from 'react';
import { Typography } from 'antd';

interface OrganizationDashboardHeaderProps {
	organizationName: string;
	onUpdate: (name: string) => unknown;
}

const { Title } = Typography;

const OrganizationDashboardHeader: React.FC<OrganizationDashboardHeaderProps> = (props) => {
	const { organizationName = '', onUpdate } = props;
	return (
		<Title 
			editable={{
				tooltip: false,
				onChange: onUpdate,
			}}
			level={2}
		>
			{organizationName}
		</Title>
	);
};

export default OrganizationDashboardHeader;
