import {
	FC, Children, ReactNode, ReactComponentElement, useContext,
} from 'react';
import { Row, Col, Typography } from 'antd';
import styled, { ThemeContext } from 'styled-components';
import ListField from './field';
import { isReactElement } from '@copilot/common/utils';

const { Title, Text } = Typography;

const SecondaryText = styled(Text)`
	&&& {
		color: ${(props) => props.theme['@text-color-secondary']};
	}
`;

export const isListField = (element: ReactNode): element is ReactComponentElement<typeof ListField> => isReactElement(element) && element.type === ListField;

const getListFields = (children: ReactNode) => {
	const fields: ReactNode[] = [];
	Children.map(children, (child) => {
		if (isListField(child)) fields.push(child);
	});
	return fields;
};

interface IListProps {
	title?: string;
	caption?: string;
}

/**
 * List Section to display a list of items
 * @param {string} title title for the list
 * @param {string} caption caption that appears below the list title
 */
const List: FC<IListProps> & { Field: typeof ListField } = (props) => {
	const { title, caption, children } = props;
	const themeContext = useContext(ThemeContext);
	const fields = getListFields(children);
	const showTitleSection = !!title || !!caption;
	return (
		<Row gutter={[0, themeContext['@spacer-num-sm']]} style={{ marginBottom: 0 }}>
			{showTitleSection && (
				<Col style={{ marginBottom: themeContext['@spacer-size-sm'] }}>
					<Title level={4} style={{ margin: 0 }}>{title}</Title>
					<SecondaryText>{caption}</SecondaryText>
				</Col>
			)}
			{fields.map((field, index) => (
				<Col key={index} span={24}>
					{field}
				</Col>
			))}
		</Row>
	);
};
List.Field = ListField;

export default List;
